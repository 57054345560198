import Zoom from "../../assets/img/zoom65-blue.webp"
import Oneclock from "../../assets/img/oneclock-inside-angle.webp"
import Dior from "../../assets/img/dior.webp"
import Autonomous from "../../assets/img/autonomous.webp"


export interface Iprojects {
  title: string;
  img: string;
  tag: string;
  color: string;
  path: string;
  id: string;
}

export const projects: Iprojects[] = [

    {
      title: "Zoom65 Mechanical keyboard",
      img: Zoom,
      tag:"3d Styleframes",
      color: "#000000",
      path: "/zoom-65",
      id: "zoom",
    },
    {
      title: "OneClock - Alarm clock",
      img: Oneclock,
      tag:"3d Styleframes",
      color: "#000000",
      path: "/oneclock",
      id: "oneclock",
    },
    {
      title: "dior Homme",
      img: Dior,
      tag:"3d Styleframes",
      color: "#000000",
      path: "/dior",
      id: "dior",
    },
    {
      title: "Autonomous Smartdesk",
      img: Autonomous,
      tag:"3d Animation",
      color: "#000000",
      path: "/autonomous",
      id: "autonomous",
    },
  
  ]